<template>
  <!--spinner-->
  <div class="fq-position-cover fq-overlay fq-overlay-default fq-flex fq-flex-center fq-flex-middle fq-text-orange spinner">
        <span class="fq-icon fq-spinner" role="status">
            <svg width="70" height="70" viewBox="0 0 30 30"><circle fill="none" stroke="#ffb231" cx="15" cy="15" r="14" style="stroke-width: 2px;"></circle></svg>
        </span>
  </div>
</template>

<script setup>

</script>


<style scoped>

</style>