import {inject, ref} from 'vue'

export default function () {
    const alerts = ref([])
    const inlineAlertTimeout = Number(inject('inlineAlertTimeout'))
    const alertDefaults = {
        message: 'The image you selected has already been uploaded earlier.',
        type: 'warning',    // error, notice, whatever appends to classname 'fq-alert-' of component FileAlert
    }
    function showAlert(alert) {
        alert.id = alerts.value.length+1;
        alerts.value.push({...alertDefaults, ...alert})
        setTimeout(
            ()=> alerts.value.splice(
                alerts.value.findIndex( (el)=>el.id === alert.id ),1
            ),
            inlineAlertTimeout
        )
    }

    return { alerts, showAlert }
}
