import { ref, inject  } from 'vue'

const formatsWithBrowserPreview = ["jpeg","jpg","png","gif","tiff","tif","webp","heic","svg","dng"]
export default function () {
    const files = ref([])
    const refFiles = ref([])
    const fileWarning = ref('')
    const maxFiles = inject('maxFiles')
    const maxRefFiles = inject('maxRefFiles')
    const supportedFormats = inject('supportedFormats')


    function addFiles(newFiles) {
        // let addedFilesNumber = 0;
        let newUploadableFiles = [...newFiles]
            .map((file) => new UploadableFile(file))
            .filter((file) => !fileExists(file.id) && fileSupported(file))
        if (files.value.length < maxFiles) {
            // addedFilesNumber = maxFiles - files.value.length
            files.value = files.value.concat(newUploadableFiles.slice(0, maxFiles - files.value.length))
        } else {
            fileWarning.value = 'You can send maximum ' + maxFiles + ' photos'
        }
    }
    function addRefFiles(newFiles) {
        let newUploadableFiles = [...newFiles]
            .map((file) => new UploadableFile(file, 'ref'))
            .filter((file) => !fileExists(file.id) && fileSupported(file))
        if (refFiles.value.length<maxRefFiles) {
            refFiles.value = refFiles.value.concat(newUploadableFiles.slice(0, maxRefFiles - refFiles.value.length))
        }
    }

    function fileExists(otherId) {
        let result = files.value.some(({ id }) => id === otherId) || refFiles.value.some(({ id }) => id === otherId)
        if (result) fileWarning.value = 'The image you selected has already been uploaded earlier.'
        return result
    }

    function fileSupported(file) {
        let result = supportedFormats.value.includes(file.fileExt.toLowerCase())
        if (!result) fileWarning.value = 'The type of uploaded image is not supported.'
        return result
    }

    function removeFile(file) {
        const index = files.value.indexOf(file)
        if (index > -1) files.value.splice(index, 1)
        // if (files.value.length === 0) refFiles.value = [];
    }
    function removeRefFile(file) {
        const index = refFiles.value.indexOf(file)
        if (index > -1) refFiles.value.splice(index, 1)
    }

    return { files, refFiles, fileWarning, addFiles, removeFile, addRefFiles, removeRefFile }
}

class UploadableFile {


    constructor(file, type='') {
        console.log(file);
        this.file = file
        this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
        this.url = URL.createObjectURL(file)
        this.instructions = '' ;
        this.mimeType = file.type ;
        this.fileExt = file.name.split('.').pop();
        this.fileType = type ;
        this.hasPreview = formatsWithBrowserPreview.indexOf(this.fileExt) !== -1;
        this.instructionsError = false;
        this.status = null
    }
}
