import {createApp} from 'vue'
import FreeQuotes from './FreeQuotes.vue'
import vue3GoogleLogin from 'vue3-google-login'

const app= createApp(FreeQuotes)
.provide('maxFiles',        process.env.VUE_APP_MAX_FILES ?? 3)
.provide('maxRefFiles',     process.env.VUE_APP_MAX_REF_FILES ?? 1)
.provide('apiURL',       process.env.VUE_APP_API_URL ?? '/ajax/free_quotes_v2.php')
.provide('inlineAlertTimeout', process.env.VUE_APP_INLINE_ALERT_TIMEOUT ?? 5000)

app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_SIGNIN_CLIENT_ID
})

app.mount('#free-quotes-vue')

