export async function uploadFiles(files, url, uuid) {
    let formData = new FormData()
    formData.append('action', 'uploadFile')
    formData.append('uuid', uuid.value)
    formData.append('step', 'step2')
    formData.append('url', window.location.href)
    files.map((file) => {
        formData.append('fileType[]', file.fileType)
        formData.append('fileQuote[]', file.instructions)
        formData.append('fileId[]', file.id)
        formData.append('file[]', file.file)
        console.log('preparing File ' + file.id);
    })
    console.log('Uploading Files ', files, url);
    return await fetch(url, { method: 'POST', body: formData })

}

export default function createUploader(url, uuid) {
    return {
        uploadFiles: function (files) {
            return uploadFiles(files, url, uuid)
        },
    }
}