<template>
  <component :is="tag" class="fq-margin-top fq-width-1-1">
    <div class="fq-grid fq-flex-middle">
      <div class="fq-width-auto">
        <div class="fq-position-relative">
          <!--img box-->
          <!--<div class="fq-img-box fq-border-rounded fq-position-relative fq-box-shadow-small" :style="'background-image: url('+file.url+')'" :title="file.file.name">-->
          <div class="fq-img-box fq-border-rounded fq-position-relative fq-box-shadow-small">
            <!--spinner-->
<!--            <div class="fq-position-cover fq-overlay fq-overlay-default fq-flex fq-flex-center fq-flex-middle fq-text-orange spinner">-->
<!--              <span class="fq-icon fq-spinner" role="status">-->
<!--                  <svg v-if="false" width="30" height="30" viewBox="0 0 30 30">-->
<!--                    <circle fill="none" stroke="#ffb231" cx="15" cy="15" r="14" style="stroke-width: 2px;"></circle>-->
<!--                  </svg>-->
<!--              </span>-->
<!--            </div>-->
            <!--fileExt-->
            <div class="fq-position-cover fq-overlay fq-overlay-default fq-flex fq-flex-center fq-flex-middle fq-fileExt">
              {{ file.fileExt }}
            </div>

            <img v-if="file.hasPreview" :src="file.url" alt="" width="" height="">
          </div>
          <!--img remove btn-->
          <div @click="$emit('remove', file)" class="fq-img-del-btn fq-flex fq-flex-middle fq-flex-center">
            <span class="fq-icon">
              <svg width="17" height="17" viewBox="0 0 20 20">
                <path fill="none" stroke="#C5C5C5" stroke-width="2" d="M16,16 L4,4"></path>
                <path fill="none" stroke="#C5C5C5" stroke-width="2" d="M16,4 L4,16"></path>
              </svg>
            </span>
          </div>
          <!--img edit btn for NotesEditor -->
<!--          -->
<!--          <div v-if="enableNotesEditor===true" class="fq-img-edit-btn fq-flex fq-flex-middle fq-flex-center">-->
<!--              <span class="fq-icon">-->
<!--                  <svg width="20" height="20" viewBox="0 0 20 20">-->
<!--                    <path fill="none" stroke="#000" d="M17.25,6.01 L7.12,16.1 L3.82,17.2 L5.02,13.9 L15.12,3.88 C15.71,3.29 16.66,3.29 17.25,3.88 C17.83,4.47 17.83,5.42 17.25,6.01 L17.25,6.01 Z"></path>-->
<!--                    <path fill="none" stroke="#000" d="M15.98,7.268 L13.851,5.148"></path>-->
<!--                  </svg>-->
<!--              </span>-->
<!--          </div>-->
        </div>

      </div>
      <div class="fq-width-expand">
        <textarea
          :class="(file.instructionsError?'fq-validation ':'') + 'fq-textarea fq-border-rounded fq-box-shadow-small'"
          :placeholder="props.placeholder"
          v-model.trim="file.instructions"
          @blur="validateInstruction(file)"></textarea>
      </div>
    </div>
  </component>
</template>

<script setup>
import {toRefs} from "vue";

const props = defineProps({
  file: { type: Object, required: true },
  tag: { type: String, default: 'li' },
  placeholder: { type: String, default: 'Enter prompts or editing instructions *' },
  selfName: { type: String, default: 'file' }, // helps to know this is file or refFile or something other coming here

})
defineEmits(['remove'])

const { file } = toRefs(props)
function validateInstruction(file){
  file.instructionsError = (file.instructions.length === 0)
  return props.selfName === 'file' || !file.instructionsError
}

</script>
<style>
  .fq-fileExt {
    color: #ffff;
    font-size: 30px;
    line-height: 1;
  }
</style>